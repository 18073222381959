import { useParams } from 'next/navigation';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import Locale from '@alltrails/shared/types/Locale';
import { getLanguageRegionCodeFromLocale } from '@alltrails/shared/utils/languageSupport';
import { useRouter } from '@alltrails/core';

type Params = {
  locale: Locale;
};

const useLanguageRegionCode = () => {
  const router = useRouter();
  const params = useParams<Params>();

  const locale = router ? router.locale : params.locale;

  return getLanguageRegionCodeFromLocale(locale as Locale) || LanguageRegionCode.English;
};

export default useLanguageRegionCode;
