import { LinkRel, LinkTarget } from '../../types';

/**
 * Filters out falsy values from the list of rels and
 * adds the 'noreferrer' rel when target is '_blank' and link is external
 * @param rel List of rels for the anchor tag
 * @param target Target of the anchor tag
 * @param external enables `target="_blank"` with `rel="noreferrer"`
 * @returns Final string to set as the rel of the anchor tag, or undefined if no rel is needed
 */
const getAnchorRel = (rel: LinkRel | LinkRel[] = [], target?: LinkTarget, external?: boolean): string | undefined => {
  const relArray = Array.isArray(rel) ? rel : [rel];
  const appliedRels: LinkRel[] = relArray.filter(str => !!str);
  if (target === '_blank' && !appliedRels.includes('noreferrer') && external) {
    appliedRels.push('noreferrer');
  }
  if (appliedRels.length) {
    return appliedRels.join(' ');
  }
  return undefined;
};

export default getAnchorRel;
