'use client';

import NextLink, { type LinkProps } from 'next/link';
import { type ComponentProps, type ReactNode, type Ref, forwardRef } from 'react';
import useIsExternalLink from '../Link/useIsExternalLink';
import getAnchorRel from '../Link/getAnchorRel';
import type { LinkRel, LinkTarget } from '../../types';

type Props = Omit<LinkProps, 'rel'> &
  Omit<ComponentProps<'a'>, 'rel'> & {
    children: ReactNode;
    outsideOfMugen?: boolean;
    rel?: LinkRel | LinkRel[];
    target?: LinkTarget;
  };

/**
 * formatNextLinkHref formats a given href for use with NextLink.
 *
 * @param { basePath, href }
 * @returns string
 */
function formatNextLinkHref({ basePath, href }: { basePath: string; href: string }) {
  // When an app uses basePath Next automatically prepends that basePath to all its hrefs.
  //
  // > For example, using /about will automatically become /docs/about when basePath is set to /docs.
  // >
  // > https://nextjs.org/docs/app/api-reference/next-config-js/basePath#links
  if (Boolean(basePath)) {
    // This allows us to write hrefs like we're used to where we always manually write out the basePath, and not end up with two basePaths in the href when Next automatically prepends it.
    return href.replace(new RegExp(`^${basePath}`), '');
  }

  return href;
}

/**
 * getBasePath returns the basePath of the current app. This is designed to work for both the app and page routers.
 *
 * basePath from useRouter works with page router, but not with app router. basePath is a valid config in both scenarios, but Next.js offers no simple way to access it at run time for app router apps.
 *
 * @returns string
 */
function getBasePath() {
  // If this env ever changes we could make our own BASE_PATH var we read in the next.config.js, but this already exists and is the simplest solution currently.
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  return process.env.__NEXT_ROUTER_BASEPATH || '';
}

export default forwardRef(function LinkOrNativeAnchor({ children, outsideOfMugen, ...rest }: Props, ref: Ref<HTMLAnchorElement>) {
  const isExternal = useIsExternalLink(rest.href);
  const basePath = getBasePath();

  // Override any props as needed.
  const linkProps = {
    ...rest,
    ref,
    rel: getAnchorRel(rest.rel, rest.target, isExternal)
  };

  // If an app like careers has a basePath of "/careers", and we're linking to "/about", it should be a native link since it can't leverage Next prefetch and is external to the current app.
  const isRelativeLinkToAnotherApp = Boolean(basePath) && !rest.href.startsWith(basePath);

  // NextLink should only ever be used for "internal" links within a single app. Prefetching only works within the scope of a single Next app.
  if (isExternal || outsideOfMugen || isRelativeLinkToAnotherApp) {
    // Prefetch doesn't exist on the native anchor element and causes hydration warnings.
    const { prefetch, ...nativeLinkAttributes } = linkProps;

    return (
      <a {...nativeLinkAttributes} data-linktype="native">
        {children}
      </a>
    );
  }

  return (
    <NextLink {...linkProps} href={formatNextLinkHref({ basePath, href: rest.href })} data-linktype="next">
      {children}
    </NextLink>
  );
});
