'use client';

import { type ButtonHTMLAttributes, type MouseEvent, type ReactNode, type Ref, forwardRef, useMemo } from 'react';
import classNames from 'classnames';
import { useLanguageRegionCode } from '@alltrails/language';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import IconRenderer, { IconDefinition } from '../IconRenderer';
import LoadingDots from '../LoadingDots';
import isAbsoluteLink from '../Link/isAbsoluteLink';
import { LinkInfo, Size } from '../../types';
import LinkInfoRenderer from '../LinkInfoRenderer';
import PlusBadge from '../PlusBadge';
import styles from './styles/styles.module.scss';

export type ButtonProps = {
  className?: string;
  disabled?: boolean;
  icon?: IconDefinition<'orientation'>;
  linkInfo?: LinkInfo;
  loading?: boolean;
  onClick?: (e?: MouseEvent<HTMLElement>) => void;
  preventLocaleHandling?: boolean;
  showPlusBadge?: boolean;
  size?: Size<'sm' | 'md' | 'lg'>;
  testId: string;
  text: ReactNode;
  title?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  variant?: 'default' | 'destructive' | 'elevated' | 'flat' | 'inverse' | 'primary' | 'accent';
  width?: 'auto' | 'full' | 'fullOnMobile';
  allowWrap?: boolean;
  stopPropagation?: boolean;
};

const Button = (
  {
    className,
    disabled,
    icon,
    linkInfo,
    loading,
    onClick,
    preventLocaleHandling = false,
    showPlusBadge,
    size = 'md',
    testId,
    text,
    title,
    type = 'button',
    variant = 'default',
    width = 'auto',
    allowWrap = false,
    stopPropagation = false
  }: ButtonProps,
  ref: Ref<HTMLElement>
): JSX.Element => {
  const languageRegionCode = useLanguageRegionCode();

  const onButtonClick = (e: MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    if (onClick) {
      onClick(e);
    }
  };
  const content = useMemo(() => {
    const plusBadge = showPlusBadge ? <PlusBadge className={styles.plusBadge} size="sm" /> : null;
    return icon ? (
      <>
        <IconRenderer icon={icon} defaults={{ color: 'currentColor', size: size === 'sm' ? 'sm' : 'md' }} />
        {text ? <span>{text}</span> : null}
        {plusBadge}
      </>
    ) : (
      <>
        <span>{text}</span>
        {plusBadge}
      </>
    );
  }, [icon, showPlusBadge, size, text]);

  const classes = classNames(
    className,
    styles.button,
    styles[size],
    styles[variant],
    styles[width],
    icon && text ? styles.lessPaddingLeft : undefined, // When just an icon is provided, by the ToggleButton for example, don't adjust the default padding
    disabled && styles.disabled, // An anchor tag cannot be disabled, so we apply a disabled class instead
    loading && styles.loading,
    allowWrap && styles.allowWrap,
    linkInfo && !disabled && styles.link
  );
  if (linkInfo) {
    if (disabled) {
      return (
        <span className={classes} data-testid={testId} ref={ref} title={title}>
          {content}
        </span>
      );
    }

    const isAbsolute = isAbsoluteLink(linkInfo.href);

    return (
      <LinkInfoRenderer
        className={classes}
        linkInfo={{ ...linkInfo, href: isAbsolute || preventLocaleHandling ? linkInfo.href : wrapUrlSafe(linkInfo.href, languageRegionCode) }}
        onClick={loading ? undefined : onButtonClick}
        title={title}
        testId={testId}
        ref={ref as React.Ref<HTMLAnchorElement>}
      >
        {content}
        {loading && !disabled ? <LoadingDots testId={`${testId}-loading-dots`} /> : null}
      </LinkInfoRenderer>
    );
  }
  return (
    // Need to disable the linting rule because it wants the button type to be hardcoded https://github.com/jsx-eslint/eslint-plugin-react/issues/1555
    // eslint-disable-next-line react/button-has-type
    <button
      className={classes}
      onClick={loading ? undefined : onButtonClick}
      disabled={disabled}
      data-testid={testId}
      title={title}
      type={type}
      ref={ref as Ref<HTMLButtonElement>}
    >
      {content}
      {loading && !disabled ? <LoadingDots className={styles.loadingDots} testId={`${testId}-loading-dots`} /> : null}
    </button>
  );
};

export default forwardRef(Button);
