import classNames from 'classnames';
import styles from './styles/styles.module.scss';

type LoadingDotsProps = {
  className?: string;
  testId?: string;
};

const LoadingDots = ({ className, testId }: LoadingDotsProps) => (
  <div className={classNames(styles.container, className)} data-testid={testId}>
    <div className={styles.dot1} />
    <div className={styles.dot2} />
    <div className={styles.dot3} />
  </div>
);

export default LoadingDots;
