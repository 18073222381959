/**
 * Generated file
 * Do not edit directly
 */

export const BORDERS_WEIGHT_SM = 1;
export const BORDERS_WEIGHT_MD = 2;
export const BORDERS_WEIGHT_LG = 3;
export const COLOR_NEUTRAL_50 = '#f6f6f4';
export const COLOR_NEUTRAL_100 = '#efefec';
export const COLOR_NEUTRAL_200 = '#dbdad2';
export const COLOR_NEUTRAL_300 = '#c2c2b8';
export const COLOR_NEUTRAL_400 = '#a7a99f';
export const COLOR_NEUTRAL_500 = '#8b9182';
export const COLOR_NEUTRAL_600 = '#656e5e';
export const COLOR_NEUTRAL_700 = '#4c5c43';
export const COLOR_NEUTRAL_800 = '#2b381f';
export const COLOR_NEUTRAL_900 = '#1c2513';
export const COLOR_NEUTRAL_950 = '#10130b'; // this is only for dark mode and shouldn't be used directly in light mode
export const COLOR_NEUTRAL_50_A = 'rgba(21, 27, 13, 0.04)';
export const COLOR_NEUTRAL_100_A = 'rgba(21, 27, 13, 0.08)';
export const COLOR_NEUTRAL_200_A = 'rgba(21, 27, 13, 0.16)';
export const COLOR_NEUTRAL_300_A = 'rgba(21, 27, 13, 0.27)';
export const COLOR_NEUTRAL_400_A = 'rgba(21, 27, 13, 0.38)';
export const COLOR_NEUTRAL_500_A = 'rgba(21, 27, 13, 0.49)';
export const COLOR_NEUTRAL_600_A = 'rgba(21, 27, 13, 0.64)';
export const COLOR_NEUTRAL_700_A = 'rgba(21, 27, 13, 0.74)';
export const COLOR_NEUTRAL_800_A = 'rgba(21, 27, 13, 0.88)';
export const COLOR_NEUTRAL_900_A = 'rgba(21, 27, 13, 0.9)';
export const COLOR_NEUTRAL_WHITE = '#ffffff';
export const COLOR_NEUTRAL_BLACK = '#000000';
export const COLOR_NEUTRAL_TRANSPARENT = 'rgba(0, 0, 0, 0)';
export const COLOR_UI_HIGHLIGHT = '#64f67b';
export const COLOR_UI_HIGHLIGHT_HOVER = '#4ade61';
export const COLOR_TEXT_PRIMARY = '#142800';
export const COLOR_TEXT_PRIMARY_HOVER = '#2b381f';
export const COLOR_TEXT_SECONDARY = '#656e5e';
export const COLOR_TEXT_SECONDARY_HOVER = '#4c5c43';
export const COLOR_TEXT_PRIMARY_INVERSE = '#ffffff';
export const COLOR_TEXT_INVERSE_HOVER = '#ffffff';
export const COLOR_TEXT_DISABLED = '#dbdad2';
export const COLOR_TEXT_PLACEHOLDER = '#656e5e';
export const COLOR_TEXT_ERROR = '#b24b34';
export const COLOR_TEXT_SUCCESS = '#2f751a';
export const COLOR_TEXT_CAUTION = '#925e0c';
export const COLOR_TEXT_INFO = '#196b8d';
export const COLOR_TEXT_PLUS = '#4563ff';
export const COLOR_TEXT_LIGHT = '#ffffff';
export const COLOR_TEXT_LIGHT_SECONDARY = 'rgba(255, 255, 255, 0.7)';
export const COLOR_TEXT_DARK = '#142800';
export const COLOR_TEXT_LINK_PRIMARY = '#142800'; // deprecate, use text tokens instead
export const COLOR_TEXT_LINK_PRIMARY_HOVER = '#2b381f';
export const COLOR_TEXT_LINK_SECONDARY = '#142800'; // deprecate, use text tokens instead
export const COLOR_TEXT_LINK_SECONDARY_HOVER = '#2b381f'; // deprecate, use text tokens instead
export const COLOR_TEXT_LINK_INVERSE = '#ffffff'; // deprecate, use text tokens instead
export const COLOR_TEXT_LINK_INVERSE_HOVER = '#efefec'; // deprecate, use text tokens instead
export const COLOR_BACKGROUND_PRIMARY = '#ffffff';
export const COLOR_BACKGROUND_PRIMARY_HOVER = '#f6f6f4';
export const COLOR_BACKGROUND_SECONDARY = '#efefec';
export const COLOR_BACKGROUND_SECONDARY_HOVER = '#dbdad2';
export const COLOR_BACKGROUND_DISABLED = '#f6f6f4';
export const COLOR_BACKGROUND_PLUS = '#ebeeff';
export const COLOR_BACKGROUND_PLUS_SECONDARY = '#a3b2ff';
export const COLOR_BACKGROUND_SUCCESS_WEAK = '#e9faec';
export const COLOR_BACKGROUND_SUCCESS_BASE = '#51c152';
export const COLOR_BACKGROUND_ERROR_WEAK = '#ffe6df';
export const COLOR_BACKGROUND_ERROR_BASE = '#b24b34';
export const COLOR_BACKGROUND_CAUTION_WEAK = '#fdf0d5';
export const COLOR_BACKGROUND_CAUTION_BASE = '#eea82f';
export const COLOR_BACKGROUND_INFO_WEAK = '#e2eff2';
export const COLOR_BACKGROUND_INFO_BASE = '#51aec8';
export const COLOR_BACKGROUND_ACCENT_WEAK = '#f7f4ed';
export const COLOR_BACKGROUND_ACCENT_BASE = '#2c5601';
export const COLOR_BACKGROUND_ACCENT_STRONG = '#142800';
export const COLOR_BACKGROUND_INVERSE = '#142800'; // this should replace bg primary inverse overlay
export const COLOR_BACKGROUND_TINTED = 'rgba(255, 255, 255, 0.7)';
export const COLOR_BACKGROUND_TINTED_INVERSE = 'rgba(25, 32, 19, 0.08)';
export const COLOR_BACKGROUND_PRIMARY_OVERLAY = 'rgba(255, 255, 255, 0.8)'; // For use in translucent buttons, toasts, tags etc.
export const COLOR_BACKGROUND_PRIMARY_INVERSE_OVERLAY = 'rgba(18, 18, 18, 0.85)'; // use bg.inverse instead and set opacity separately
export const COLOR_BORDER_SEPARATOR = '#dbdad2';
export const COLOR_BORDER_INTERACTIVE = '#c2c2b8';
export const COLOR_BORDER_INTERACTIVE_ACCENT = '#142800';
export const COLOR_BORDER_INTERACTIVE_ACTIVE = '#142800';
export const COLOR_BORDER_INTERACTIVE_HOVER = '#a7a99f';
export const COLOR_BORDER_DISABLED = '#dbdad2';
export const COLOR_BORDER_ERROR = '#b24b34';
export const COLOR_BORDER_FOCUS_PRIMARY = 'rgba(110, 221, 127, 0.7)'; // for web focus states that match primary ctas
export const COLOR_BORDER_FOCUS_DEFAULT = 'rgba(101, 110, 94, 0.7)'; // for web focus states that match default and non-primary ctas
export const COLOR_SHADOW = 'rgba(0, 0, 0, 0.3)';
export const COLOR_INTERACTIVE_ACCENT = '#142800'; // interactive items that become neon in dark mode
export const COLOR_INTERACTIVE_PRIMARY = '#264311';
export const COLOR_INTERACTIVE_DEFAULT = '#142800'; // where used as foreground, deprecate for in favor of text tokens
export const COLOR_INTERACTIVE_SUBTLE = '#656e5e'; // where used as foreground, deprecate for in favor of text tokens
export const COLOR_INTERACTIVE_ACCENT_HOVER = '#51c152';
export const COLOR_INTERACTIVE_PRIMARY_HOVER = '#2b381f';
export const COLOR_INTERACTIVE_DEFAULT_HOVER = '#2b381f'; // where used as foreground, deprecate for in favor of text tokens
export const COLOR_INTERACTIVE_SUBTLE_HOVER = '#4c5c43'; // where used as foreground, deprecate for in favor of text tokens
export const COLOR_DIFFICULTY_EASY = '#2f751a';
export const COLOR_DIFFICULTY_MODERATE = '#196b8d';
export const COLOR_DIFFICULTY_HARD = '#b24b34';
export const COLOR_UV_1 = '#a2e4a0';
export const COLOR_UV_2 = '#fccc78';
export const COLOR_UV_3 = '#f39d4f';
export const COLOR_UV_4 = '#f87559';
export const COLOR_UV_5 = '#a386aa';
export const COLOR_BADGE_COMPLETE = '#2b381f';
export const COLOR_BADGE_VERIFIED = '#2b381f';
export const COLOR_MAP_ROUTE = '#64f67b'; // Represents AllTrails specified route information, like route polyline, route elevation etc
export const COLOR_MAP_ROUTE_OUTLINE = '#2f751a'; // border for route
export const COLOR_MAP_ACTIVITY = '#219ad8'; // Represents users activity data, like the path a user has taken during an activity
export const COLOR_MAP_ACTIVITY_OUTLINE = '#24505e'; // Custom color. Border for activity
export const COLOR_MAP_CUSTOM_ROUTE_1 = '#f87559';
export const COLOR_MAP_CUSTOM_ROUTE_2 = '#51aec8';
export const COLOR_MAP_CUSTOM_ROUTE_3 = '#eea82f';
export const COLOR_MAP_CUSTOM_ROUTE_4 = '#a57ed8';
export const COLOR_MAP_CUSTOM_ROUTE_1_OUTLINE = '#b24b34';
export const COLOR_MAP_CUSTOM_ROUTE_2_OUTLINE = '#196b8d';
export const COLOR_MAP_CUSTOM_ROUTE_3_OUTLINE = '#925e0c';
export const COLOR_MAP_CUSTOM_ROUTE_4_OUTLINE = '#69546d';
export const COLOR_MAP_POI = '#e9faec'; // when a trail intersects a POI
export const COLOR_MAP_POI_OUTLINE = '#2c5601'; // Border for POI
export const COLOR_MAP_CONDITION_OUTLINE = '#000000'; // Border for polyline with conditions overlay
export const COLOR_CONDITIONS_GRASS = '#85b767';
export const COLOR_CONDITIONS_WOOD = '#805c5c';
export const COLOR_CONDITIONS_NATURAL = '#af8657';
export const COLOR_CONDITIONS_SAND = '#e5bf79';
export const COLOR_CONDITIONS_GRAVEL = '#d4d2bf';
export const COLOR_CONDITIONS_ROCK = '#8a8783';
export const COLOR_CONDITIONS_PAVED = '#555f52';
export const COLOR_CONDITIONS_METAL = '#acbec3';
export const COLOR_CONDITIONS_SNOW = '#92d8f5';
export const COLOR_CONDITIONS_PLASTIC = '#6174cb';
export const COLOR_CONDITIONS_RUBBER = '#9258ae';
export const COLOR_CONDITIONS_STEPS = '#c25c5b';
export const COLOR_DATAVIZ_1 = '#3571ca';
export const COLOR_SCRIM_LIGHT = 'rgba(0, 0, 0, 0.4)';
export const COLOR_SCRIM_DARK = 'rgba(0, 0, 0, 0.7)';
export const COLOR_VENDOR_FACEBOOK = '#3c5997';
export const COLOR_VENDOR_GOOGLE = '#4184f3';
export const COLOR_VENDOR_APPLE = '#000000';
export const COLOR_BRAND_PRIMARY = '#2c5601';
export const COLOR_BRAND_DARK = '#142800';
export const COLOR_BRAND_NEON = '#64f67b';
export const COLOR_BRAND_CREAM = '#f7f4ed';
export const COLOR_BRAND_PEACH = '#f9d9b3';
export const COLOR_BRAND_LAVENDER = '#d7cadb';
export const COLOR_BRAND_PLUS_LIGHT = '#a3b2ff';
export const COLOR_BRAND_PLUS_DARK = '#4563ff';
export const COLOR_BRAND_DARKEST = '#1e4304'; // deprecate
export const COLOR_BRAND_LIGHT = '#69a041'; // deprecate
export const COLOR_BRAND_PRO = '#e9501e'; // deprecate
export const COLOR_GREEN_100 = '#e9faec';
export const COLOR_GREEN_300 = '#a2e4a0';
export const COLOR_GREEN_700 = '#2f751a';
export const COLOR_GREEN_900 = '#1c2513';
export const COLOR_GREEN_BASE = '#51c152';
export const COLOR_RED_100 = '#ffe6df';
export const COLOR_RED_300 = '#fdaa9b';
export const COLOR_RED_700 = '#b24b34';
export const COLOR_RED_900 = '#38201a';
export const COLOR_RED_BASE = '#f87559';
export const COLOR_BLUE_100 = '#e2eff2';
export const COLOR_BLUE_300 = '#83c9dc';
export const COLOR_BLUE_700 = '#196b8d';
export const COLOR_BLUE_900 = '#19252a';
export const COLOR_BLUE_BASE = '#51aec8';
export const COLOR_YELLOW_100 = '#fdf0d5';
export const COLOR_YELLOW_300 = '#fccc78';
export const COLOR_YELLOW_700 = '#925e0c';
export const COLOR_YELLOW_900 = '#3a2a0e';
export const COLOR_YELLOW_BASE = '#eea82f';
export const COLOR_PURPLE_100 = '#d7cadb';
export const COLOR_PURPLE_BASE = '#a386aa';
export const COLOR_ORANGE_100 = '#f9d9b3';
export const COLOR_ORANGE_300 = '#f0c389';
export const COLOR_ORANGE_BASE = '#f39d4f';
export const COLOR_PLUS_100 = '#ebeeff';
export const COLOR_PLUS_300 = '#a3b2ff';
export const COLOR_PLUS_500 = '#5772ff';
export const COLOR_PLUS_600 = '#4563ff';
export const COLOR_PLUS_700 = '#415096';
export const COLOR_PLUS_900 = '#171e40';
export const FONT_FAMILY_PRIMARY = 'Manrope';
export const FONT_FAMILY_SECONDARY = 'Beatrice';
export const FONT_WEIGHT_NORMAL = 'normal';
export const FONT_WEIGHT_BOLD = 'bold';
export const FONT_LINEHEIGHT_400 = 1.2;
export const FONT_LINEHEIGHT_500 = 1.18;
export const FONT_LINEHEIGHT_600 = 1.15;
export const FONT_LINEHEIGHT_700 = 1.1;
export const FONT_LINEHEIGHT_LOOSE = 1.7; // for use in paragraphs
export const FONT_LINEHEIGHT_DEFAULT = 1.5;
export const FONT_LINEHEIGHT_TIGHT = 1.3; // deprecate
export const FONT_LINEHEIGHT_700_RESPONSIVE = 1.06;
export const FONT_LINEHEIGHT_600_RESPONSIVE = 1.1;
export const FONT_LINEHEIGHT_500_RESPONSIVE = 1.15;
export const FONT_LINEHEIGHT_400_RESPONSIVE = 1.2;
export const FONT_SIZE_50 = 11;
export const FONT_SIZE_100 = 13;
export const FONT_SIZE_200 = 16;
export const FONT_SIZE_300 = 20;
export const FONT_SIZE_400 = 24;
export const FONT_SIZE_500 = 28;
export const FONT_SIZE_600 = 32;
export const FONT_SIZE_700 = 44;
export const FONT_SIZE_400_RESPONSIVE = 28;
export const FONT_SIZE_500_RESPONSIVE = 32;
export const FONT_SIZE_600_RESPONSIVE = 36;
export const FONT_SIZE_700_RESPONSIVE = 60;
export const FONT_LETTERSPACING_400 = -0.25;
export const FONT_LETTERSPACING_500 = -0.5;
export const FONT_LETTERSPACING_600 = -0.5;
export const FONT_LETTERSPACING_700 = -1;
export const FONT_LETTERSPACING_700_RESPONSIVE = -1;
export const FONT_LETTERSPACING_600_RESPONSIVE = -1;
export const FONT_LETTERSPACING_500_RESPONSIVE = -0.5;
export const FONT_LETTERSPACING_400_RESPONSIVE = -0.5;
export const RADIUS_NONE = 0;
export const RADIUS_SM = 4;
export const RADIUS_MD = 8;
export const RADIUS_LG = 16;
export const RADIUS_XL = 24;
export const RADIUS_ROUND = 9999;
export const RADIUS_DRAWER = 16;
export const RADIUS_CARD = 16;
export const BUTTON_RADIUS_SM = 9999;
export const BUTTON_RADIUS_MD = 9999;
export const BUTTON_RADIUS_LG = 9999;
export const SHADOW_100 = '0px 2px 4px 0px rgba(0, 0, 0, 0.14), 0px 0px 1px 0px rgba(0, 0, 0, 0.30)';
export const SHADOW_200 = '0px 4px 8px 0px rgba(0, 0, 0, 0.18), 0px 0px 1px 0px rgba(0, 0, 0, 0.30)';
export const SHADOW_250 = '0px 0px 8px 0px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.12)';
export const SHADOW_300 = '0px 8px 12px 0px rgba(0, 0, 0, 0.15), 0px 0px 1px 0px rgba(0, 0, 0, 0.30)';
export const SHADOW_SM_X = 0;
export const SHADOW_SM_Y = 1;
export const SHADOW_SM_RADIUS = 6;
export const SHADOW_SM_SPREAD = -2;
export const SHADOW_MD_X = 0;
export const SHADOW_MD_Y = 4;
export const SHADOW_MD_RADIUS = 8;
export const SHADOW_MD_SPREAD = -2;
export const SHADOW_LG_X = 0;
export const SHADOW_LG_Y = 8;
export const SHADOW_LG_RADIUS = 16;
export const SHADOW_LG_SPREAD = -1;
export const SPACE_0 = 0;
export const SPACE_4 = 4; // deprecate
export const SPACE_8 = 8; // deprecate
export const SPACE_12 = 12; // deprecate
export const SPACE_16 = 16; // deprecate
export const SPACE_24 = 24; // deprecate
export const SPACE_32 = 32; // deprecate
export const SPACE_40 = 40; // deprecate
export const SPACE_48 = 48; // deprecate
export const SPACE_50 = 4;
export const SPACE_64 = 64; // deprecate
export const SPACE_96 = 96; // deprecate
export const SPACE_100 = 8;
export const SPACE_128 = 128; // investigate
export const SPACE_150 = 12;
export const SPACE_200 = 16;
export const SPACE_300 = 24;
export const SPACE_450 = 36;
export const SPACE_600 = 48;
export const SPACE_800 = 64;
export const SPACE_1200 = 96;
export const SPACE_PAGE_HORIZONTAL_MOBILE = 24;
export const DURATION_SHORT = '300ms';
export const DURATION_DEFAULT = '500ms';
export const DURATION_LONG = '2000ms';
export const TIMING_LINEAR = 'cubic-bezier(0, 0, 1, 1)';
export const TIMING_EASE_OUT = 'cubic-bezier(0.32, 0.94, 0.60, 1)';
export const TIMING_EASE_IN = 'cubic-bezier(0.15, 0, 0.15, 0)';
export const Z_INDEX_DEEP = -99999;
export const Z_INDEX_DEFAULT = 1;
export const Z_INDEX_STICKY = 100;
export const Z_INDEX_DROPDOWN = 200;
export const Z_INDEX_OVERLAY = 300;
export const Z_INDEX_MODAL = 400;
export const Z_INDEX_POPOVER = 800;
export const Z_INDEX_SPINNER = 900;
export const Z_INDEX_TOAST = 1000;
export const GUIDE_BLACKFOREST_BACKGROUND = '#131E0E';
export const GUIDE_BLACKFOREST_BLOCKQUOTE = '#ECF9EE';
export const GUIDE_EVERGREEN_BACKGROUND = '#142800';
export const GUIDE_EVERGREEN_BLOCKQUOTE = '#ECF9EE';
export const GUIDE_MAROON_BACKGROUND = '#38201a';
export const GUIDE_MAROON_BLOCKQUOTE = '#FFE0E0';
export const GUIDE_BROWN_BACKGROUND = '#3a2a0e';
export const GUIDE_BROWN_BLOCKQUOTE = '#F9DAB4';
export const GUIDE_GOLDENROD_BACKGROUND = '#845B15';
export const GUIDE_GOLDENROD_BLOCKQUOTE = '#FDF3E2';
export const GUIDE_REDWOOD_BACKGROUND = '#9A4020';
export const GUIDE_REDWOOD_BLOCKQUOTE = '#ffe6df';
export const GUIDE_MIDNIGHT_BACKGROUND = '#19252a';
export const GUIDE_MIDNIGHT_BLOCKQUOTE = '#e2eff2';
export const GRID_PAGE_MAX_WIDTH = 1280;
export const BREAKPOINT_XS_SIZE = 360;
export const BREAKPOINT_XS_MARGIN = 16;
export const BREAKPOINT_SM_SIZE = 760;
export const BREAKPOINT_SM_MARGIN = 24;
export const BREAKPOINT_MD_SIZE = 1120;
export const BREAKPOINT_MD_MARGIN = 48;
export const BREAKPOINT_LG_SIZE = 1920;
export const BREAKPOINT_LG_MARGIN = 80;
export const SIZE_ICON_XS = '16';
export const SIZE_ICON_SM = '20';
export const SIZE_ICON_MD = '24';
export const SIZE_ICON_LG = '32';
export const SIZE_CONTROLS_SM = '36';
export const SIZE_CONTROLS_MD = '48';
export const SIZE_CONTROLS_LG = '56';
export const SIZE_HEADER_HEIGHT_DESKTOP = 64;
export const SIZE_HEADER_HEIGHT_MOBILE = 64;
